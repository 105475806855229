<!-- 广告详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageTopButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageTopButtons"
          :key="index"
          v-bind="button"
          :disabled="button.enable"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="120">
        <div slot="header" class="clearfix">
          <span class="title">广告</span>
        </div>
        <ykc-detail-item-plus label="广告名称">
          <span>{{ dealData(detail.name) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="广告位置">
          <span>{{ dealData(detail.launchTypeMean) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="广告状态">
          <span>
            {{ dealData(detail.statusMean) }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="广告图片">
          <div class="block" @click="showPreView">
            <el-image style="width: 100px; height: 100px" :src="detail.image"></el-image>
          </div>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="投放终端">
          {{ dealData(detail.launchTerminalTypeMean) }}
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="客户端跳转">
          <span>
            {{ dealData(detail.appSkipTypeMean) }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="客户端跳转地址">
          <span>
            {{ dealData(detail.appSkipUrl) }}
          </span>
        </ykc-detail-item-plus>

        <ykc-detail-item-plus label="微信跳转">
          <span>
            {{ dealData(detail.wechatSkipTypeMean) }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="微信小程序id">
          <span>
            {{ dealData(detail.wechatAppId) }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="微信跳转路径">
          <span>
            {{ dealData(detail.wechatSkipUrl) }}
          </span>
        </ykc-detail-item-plus>

        <ykc-detail-item-plus label="投放时间">
          <span>
            {{ dealData(detail.launchBeginTime) + '到' + dealData(detail.launchEndTime) }}
          </span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改人">
          <span>{{ dealData(detail.modifiedName) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="修改时间">
          <span>{{ dealData(detail.modifiedTime) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="创建人">
          <span>{{ dealData(detail.createdName) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="创建时间">
          <span>{{ dealData(detail.createdTime) }}</span>
        </ykc-detail-item-plus>
        <ykc-detail-item-plus label="用户范围">
          <span>{{ dealData(detail.userScopeTypeMean) }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        ref="YkcTable"
        v-show="detail.userScopeType !== '3'"
        :data="tableData"
        :columns="tableColumns"
        title="活动用户列表"
        operateFixedType="right">
        <template #headerButtons>
          <ykc-button
            v-for="(button, index) in pageButtons.filter(item => item.enabled())"
            :key="button.id || index"
            v-bind="button"
            :disabeld="true"
            @click="handlePageButtonClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          direct="flex-end"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { CustomDriverAccounts, adsApi } from '@/service/apis';
  import { offlineExport, dealData, code } from '@/utils';
  import YkcDialog from '@/components/ykc-ui/dialog';

  export default {
    name: 'platformCouponDetail',
    data() {
      return {
        id: '',
        visible: false,
        detail: {
          id: null,
          groupName: '',
          stationCount: 0,
          labelStatus: '0',
          labelStatusMean: '',
          groupDimension: '0',
          groupDimensionMean: '',
          createdBy: null,
          createdTime: '',
          modifiedBy: null,
          modifiedTime: '',
          stationList: null,
          createdName: null,
          modifiedName: null,
        },
        dictionary: {},
        headerInfo: {},
        //  流水类型

        pageTopButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
        pageButtons: [
          {
            id: '1',
            enabled: () => code('ads:downlist'),
            text: '导出',
            type: 'plain',
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],

        tableColumns: [
          { label: '客户编码', prop: 'orgId', minWidth: '100px' },
          { label: '客户名称', prop: 'orgName', minWidth: '100px' },
          { label: '客户类型', prop: 'orgType', minWidth: '100px' },
        ],
        tableColumnsGroup: [
          { label: '用户分组', prop: 'groupName', minWidth: '100px' },
          { label: '用户数量', prop: 'num', minWidth: '100px' },
        ],

        pageInfo: {
          total: 0,
          current: 1,
          size: 10,
        },
        searchParams: {
          id: this.id,
        },
        showDrawer: false,
        isEdit: false,
      };
    },
    computed: {},
    created() {
      const { id } = this.$route.query;
      this.id = id;
      this.searchParams.id = id;
      this.getDetail();
      this.queryHeaderInfo();
      this.searchTableList();
    },
    methods: {
      dealData,
      /**
       * @function queryHeaderInfo
       * @desc 查询头部新消息
       * */
      queryHeaderInfo() {
        CustomDriverAccounts.detailHeaderInfo({ userId: this.id }).then(res => {
          this.headerInfo = res || {};
        });
      },
      /** 根据条件查询数据 */
      searchTableList() {
        const params = {
          ...this.searchParams,
          current: this.pageInfo.current,
          size: this.pageInfo.size,
        };
        adsApi.queryCustomerForPage(params).then(response => {
          this.tableData = response?.records || [];
          this.pageInfo.total = response?.total || 0;
        });
      },
      /** 导出文件 */
      doExportFile() {
        offlineExport(
          {
            downloadType: 'marketing_export',
            jsonNode: {
              downloadKey: 'customer_export',
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      onAddDrawerClose(done) {
        done();
      },
      /** 点击抽屉取消按钮 callback */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 1;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      showPreView() {
        this.visible = !this.visible;
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.ruleForm = {};
      },
      getDetail() {
        // 编辑回显
        if (this.id) {
          adsApi
            .detail({ id: this.id })
            .then(res => {
              this.detail = {
                ...res,
              };
              this.getTreeData(true, res.configIds);
              this.$refs.adsImg.fileList = [{ url: res.image }];
            })
            .catch(() => {});
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .content {
    // display: flex;
    .clearfix {
      height: 19px;
      display: flex;
      align-items: center;
    }
    .box-card {
      margin: 0;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      .status {
        margin-left: 10px;
        color: #fff;
        height: 20px;
        border-radius: 10px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 8px;
        line-height: 20px;
        &.stationStatus {
          &.status1000135 {
            background-color: #009f09;
          }
          &.status1000136 {
            background-color: #e81b1b;
          }
        }
        &.infoStatus {
          &.status1 {
            background-color: #009f09;
          }
          &.status2 {
            background-color: #ccc;
            color: #fff;
          }
          &.status0 {
            background-color: #e81b1b;
          }
        }
      }
      ::v-deep .el-form-item {
        display: flex;
        align-items: center;
      }
      table {
        width: calc(100% - 30px);
        border-left: 1px solid #ddd;
        border-top: 1px solid #ddd;
        margin-left: 30px;
        margin-top: 20px;
        th {
          background-color: #f6f7f8;
          font-weight: bold;
        }
        th,
        td {
          font-size: 12px;
          text-align: left;
          padding: 0 10px;
          line-height: 40px;
          height: 40px;
          border-right: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          ::v-deep .el-form-item {
            margin-bottom: 0;
            input {
              width: 100px;
            }
          }
        }
      }
      .title {
        position: relative;
        &-flag {
          margin-left: 8px;
          padding: 2px 18px;
          background: rgba(77, 140, 253, 0.1);
          border-radius: 2px;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4d8cfd;
          line-height: 17px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
</style>
